import React, { useState } from 'react';
import PageInfo from '../../components/PageInfo';

function Dots(props) {
  return (
    <div className="dots">{props.dots}</div>
  );
}

function ToolNumberVisualizer(props) {
  PageInfo(props);

  const [dots, setDots] = useState("");
  const [num, setNum] = useState("");
  const [loading, setLoading] = useState("");

  function validateNum(num) {
    if (isNaN(num)) return 0;
    const numberNum = Number(num);
    if (numberNum < 0) {
      return 0;
    }
    if (numberNum >= 1000000) {
      return 1000000;
    }
    return num;
  }

  function handleChange(event) {
    setNum(validateNum(event.target.value));
    setLoading("🕒");
    setTimeout(() => {
      setDots("🙂".repeat(validateNum(event.target.value)));
      setLoading("");
    }, 0);
  }

  return (
    <div className="text-align-center">

      <div className="title-section">
        Number Visualizer
      </div>

      <div className="subtitle-section">
        Turn numbers into a visual representation on screen!
      </div>

      <div style={{ marginTop: "8px" }}>
        <input
          className="form-input"
          type="number"
          placeholder="Enter a number"
          value={num}
          min="0"
          onInput={handleChange}
        />
        <div className="form-input-right">{loading}</div>
      </div>
      <Dots
        dots={dots}
      />
    </div>
  );
}

export default ToolNumberVisualizer;

//ToolNumberVisualizer

//<input id="dotNum" type="number" onChange={this.dotsUpdate(this.value)} onInput={this.dotsUpdate(this.value)}></input>
//<div id="dots" className="dots">{this.renderDots()}</div>