import React, { Component } from 'react';

class NotFound extends Component {
  render() {
    return (
      <div className="page-content">
        <div className="title-section">
          Page not found 😕
        </div>
      </div>
    )
  }
}

export default NotFound;